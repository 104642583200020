import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {ToastData, ToastOptions, ToastyService} from 'ng2-toasty';
import { from, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UtilService {

  position = 'bottom-right';
  public emailRegex = '[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}';

  constructor(
    private toastyService:ToastyService,
    private router: Router,
  ) { }
  public passwordRegex = '.{8,}';
  

  public onAuthSuccess(response: any, action?: string) {
    if (action) { this.doRouting(action); }
    this.toastyService.clearAll();
    this.addToast({ title: response, type: 'success', msg: '😀😀😀' });
  }

  public notifyError(msg) {
    this.addToast({ title: msg, type: 'error', msg: '😞😞😞' });
  }

  public notifySuccess(msg) {
    this.addToast({ title: msg, type: 'success', msg: '😀😀😀' });
  }

  public notifyWarning(msg) {
    this.addToast({ title: msg, type: 'warning', msg: '☠️☠️☠️' });
  }
  public notifyInfo(msg) {
    this.addToast({ title: msg, type: 'info', msg: '🙅🏻‍♀️🙅🏻‍♀️🙅🏻‍♀️' });
  }
  public notifyWaiting(msg) {
    this.addToast({ title: msg, type: 'waiting', msg: '🦖🦖' });
  }

  public clearNotifications() {
    this.toastyService.clearAll();
  }

  public doRouting(params?: any) {
    this.router.navigate([params]);
  }

  addToast(options) {
    this.toastyService.clearAll();
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg || '-',
      showClose: true,
      timeout: 3000,
      theme: 'bootstrap',
      onAdd: (toast: ToastData) => {
        /* added */
      },
      onRemove: (toast: ToastData) => {
        /* removed */
      }
    };

    switch (options.type) {
      case 'default': this.toastyService.default(toastOptions); break;
      case 'info': this.toastyService.info(toastOptions); break;
      case 'success': this.toastyService.success(toastOptions); break;
      case 'wait': this.toastyService.wait(toastOptions); break;
      case 'error': this.toastyService.error(toastOptions); break;
      case 'warning': this.toastyService.warning(toastOptions); break;
    }
  }

  public handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      const err = error.error ? error.error.message : error;
      this.clearNotifications();
      this.notifyError(err);
      return from(result);
    };
  }
}
