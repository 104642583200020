import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from 'src/app/theme/shared/services/profile.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {

  adminData:any;

  constructor(
   private router:Router,
   private adminProfile:ProfileService
  ) {

   this.adminProfile.subject.subscribe(data =>{ 
    if(!data) return 
    this.adminData = data.data.iOwnerId
  })
  }

  ngOnInit() { 
   
  }

  logout(){
    Swal.fire({
      title: 'Logout ?',
      text: 'Are you sure!',
      showCloseButton: true,
      showCancelButton: true,

    }).then((res) => {
      if (res.dismiss) {

      } else {
        localStorage.clear()
        this.router.navigateByUrl('/sign-in')
      }
    })
  }
}
