import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { environment } from "../../../../environments/environment";
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseUrl = environment.apiUrl + environment.version

  constructor(
    private http: HttpClient,
    private util: UtilService,
    private router: Router
  ) { }

  isLoggedIn(url): boolean {
    const storageValues = JSON.parse(localStorage.getItem('accessToken'));
    if (storageValues !== null && storageValues !== '') {
      if (url === '/' || url === '/sign-in') {
        this.router.navigateByUrl('/crm/dashboard');
        return false;
      } else {
        return true;
      }
    } else {
      if (url !== '/sign-in' && url !== '/forgot-password') {
        if (url === '/forgot-password') {
          this.router.navigate(['/forget-password']);
        } else {
          this.router.navigate(['/sign-in']);
        }
        return false;
      } else {
        return true;
      }
    }

  }

  login(data) {
    return this.http.post(`${this.baseUrl}admin/auth/clublogin`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
      observe: 'response'
    }).pipe(
      map((response: any) => response),
      catchError(this.util.handleError('login', []))
    );
  }
  forgotPassword(data) {
    return this.http.post<any>(`${this.baseUrl}admin/auth/forgotpassword`, data).pipe(
        map(user => { return user }),
        catchError(this.util.handleError('forgotPassword', []))

    );
}
}
