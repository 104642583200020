import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'other',
    title: 'Club Admin',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'crm',
        title: 'CRM',
        type: 'collapse',
        icon: 'feather icon-box',
        children:[
          {
						id: 'dashboard',
						title: 'Dashboard',
						type: 'item',
						url: '/crm/dashboard',
					},
        ]
      },
      {
        id: 'User',
        title: 'User',
        type: 'collapse',
        icon: 'feather icon-user',
        children:[
          {
						id: 'user',
						title: 'User Management',
						type: 'item',
						url: '/user/user-management',
					},
          {
						id: 'agent',
						title: 'Agent Management',
						type: 'item',
						url: '/user/agent-management',
					},
        ]
      },
      {
				id: 'rummy',
				title: 'Rummy',
				type: 'collapse',
				icon: 'feather icon-sliders',
				children: [
					{
						id: 'Points-Rummy',
						title: 'Points Rummy',
						type: 'item',
						url: '/rummy/points-rummy',
					},
					{
						id: 'pool-rummy',
						title: 'Pool Rummy',
						type: 'item',
						url: '/rummy/pool-rummy',
					},
					{
						id: 'deals-rummy',
						title: 'Deal Rummy',
						type: 'item',
						url: '/rummy/deals-rummy',
					},
				]
			},

    ]
  }
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
