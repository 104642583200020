import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class InterceptorService implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (localStorage.getItem('accessToken') != null) {
            const storageValues = JSON.parse(localStorage.getItem('accessToken'));
            req = req.clone({
                setHeaders: {
                    Authorization: storageValues.Authorization,
                }
            });
        }

        return next.handle(req);
    }
}
